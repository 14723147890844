import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Title } from '@delab-team/de-ui';
import Lottie from 'react-lottie';
import * as disabledSticker from '../../assets/stickers/disabled.json';
import s from './temporarily-disabled.module.scss';
export const TemporarilyDisabled = () => {
    const approveOptions = {
        loop: true,
        autoplay: true,
        animationData: disabledSticker,
        rendererSettings: { preserveAspectRatio: 'xMidYMid slice' }
    };
    return (_jsxs("div", { className: s.content, children: [_jsx(Lottie, { options: approveOptions, height: 220, isClickToPauseDisabled: true, width: 220 }), _jsx(Title, { variant: "h1", children: "Temporarily disabled" })] }));
};
