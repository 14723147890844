/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
/* eslint-disable max-len */
import axios from 'axios';
export class TonApi {
    constructor(network) {
        this._url = 'https://tonapi.io/v1/';
        this._url2 = 'https://tonapi.io/v2/';
        this._urlTest = 'https://testnet.tonapi.io/v1/';
        this._url2Test = 'https://testnet.tonapi.io/v2/';
        // private _token: string = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIxMjI0Iiwic2NvcGUiOiJjbGllbnQifQ.vvtwTq9kO89CNP2635wImtrzshdrAM9AYaIbQNqfJHQ'
        this._token = 'AFXRKLZM2YCJ67AAAAAE4XDRSACSYEOYKQKOSUVUKMXNMP2AKUTWJ2UVBPTTQZWRGZMLALY';
        this._network = network;
    }
    async send(url, data, type = false) {
        let urlFull;
        if (this._network === 'mainnet' && type === false) {
            urlFull = this._url;
        }
        if (this._network === 'testnet' && type === false) {
            urlFull = this._urlTest;
        }
        if (this._network === 'testnet' && type === true) {
            urlFull = this._url2Test;
        }
        if (this._network === 'mainnet' && type === true) {
            urlFull = this._url2;
        }
        console.log('url', urlFull);
        const res = await axios.get(`${urlFull}${url}?${new URLSearchParams(data)}`, { headers: { Authorization: `Bearer ${this._token}` } });
        if (res.data.error) {
            console.error(res.data.result);
            return undefined;
        }
        return res.data;
    }
    async getItems(address) {
        const data = await this.send('nft/getItems', { addresses: address });
        console.log(data);
        return data;
    }
    async getCollection(address) {
        const data = await this.send('nft/getCollection', { account: address });
        console.log(data);
        return data;
    }
    async getCollectionV2(address) {
        const data = await this.send(`nfts/collections/${address}`, {}, true);
        console.log(data);
        return data;
    }
    async getCollections(limit = 100, offset = 0) {
        const data = await this.send('nft/getCollections', { limit, offset });
        console.log(data);
        return data;
    }
    async getCollectionsV2(limit = 100, offset = 0) {
        const data = await this.send('nfts/collections', { limit, offset }, true);
        console.log(data);
        return data;
    }
    async searchItems(address, limit = 100, offset = 0) {
        const data = await this.send('nft/searchItems', { collection: address, limit, offset });
        console.log(data);
        return data;
    }
    async searchItemsFromUser(address, limit = 100, offset = 0) {
        const data = await this.send('nft/searchItems', { owner: address, limit, offset, include_on_sale: true });
        console.log(data);
        return data;
    }
    async searchItemsfull(limit = 100, offset = 0) {
        const data = await this.send('nft/searchItems', { limit, offset });
        console.log(data);
        return data;
    }
    async getInfoUser(address) {
        const data = await this.send('account/getInfo', { account: address });
        console.log(data);
        return data;
    }
    async getInfoUserV2(address) {
        const data = await this.send(`accounts/${address}`, {}, true);
        console.log(data);
        return data;
    }
    async getTransactionsV2(address) {
        const data = await this.send(`blockchain/accounts/${address}/transactions`, { before_lt: 0, limit: 100 }, true);
        console.log(data);
        return data;
    }
    async getItemsV2(address, limit, offset) {
        const data = await this.send(`nfts/collections/${address}/items`, { limit, offset }, true);
        console.log(data);
        return data;
    }
    async getItemV2(address) {
        const data = await this.send(`nfts/${address}`, {}, true);
        console.log(data);
        return data;
    }
    async getJettonInfo(address) {
        const data = await this.send(`jettons/${address}`, {}, true);
        console.log(data);
        return data;
    }
    async getProfileItemsV2(accountId, collectionAddress, limitNft, offsetNft) {
        const data = await this.send(`accounts/${accountId}/nfts?collection=${collectionAddress}`, { indirect_ownership: false, offset: offsetNft, limit: limitNft }, true);
        return data.nft_items;
    }
}
