import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTonAddress, useTonConnectUI } from '@tonconnect/ui-react';
import { ProviderTonConnect } from '@delab-team/ton-network-react';
import { AppInner } from '@delab-team/de-ui';
import { TemporarilyDisabled } from './pages/temporarily-disabled';
import { TonApi } from './logic/tonapi';
const isTestnet = window.location.host.indexOf('localhost') >= 0
    ? true
    : window.location.href.indexOf('testnet') >= 0;
// const isTestnet = false
export const App = () => {
    const [firstRender, setFirstRender] = useState(false);
    const [isTg, setIsTg] = useState(false);
    const [balance, setBalance] = useState(undefined);
    const [createdFund, setCreatedFund] = useState(false);
    const [tonConnectUI, setOptions] = useTonConnectUI();
    const navigate = useNavigate();
    const RawAddress = useTonAddress();
    const api = new TonApi(isTestnet ? 'testnet' : 'mainnet');
    const addressCollection = ['EQDtUbSCZk7aA9HHezxLJyzmMpWabMeo0KAG7gdm_ds3qkt4', 'EQBVdUsIL3jBn211_wrFwm8AJBnF_r9kefjgcOfrU8a2yyHp'];
    async function loadUser(address) {
        const data = await api.getInfoUserV2(address);
        if (!data || !data?.balance) {
            return undefined;
        }
        setBalance(data?.balance.toString());
        return true;
    }
    useEffect(() => {
        if (!firstRender) {
            setFirstRender(true);
            if (tonConnectUI) {
                const networkProvider = new ProviderTonConnect(tonConnectUI, isTestnet);
                console.log(networkProvider);
            }
            const isTgCheck = window.Telegram.WebApp.initData !== '';
            const TgObj = window.Telegram.WebApp;
            const bodyStyle = document.body.style;
            setIsTg(isTgCheck);
            if (isTgCheck) {
                TgObj.ready();
                TgObj.enableClosingConfirmation();
                TgObj.expand();
                setIsTg(true);
                bodyStyle.backgroundColor = 'var(--tg-theme-secondary-bg-color)';
                bodyStyle.setProperty('background-color', 'var(--tg-theme-secondary-bg-color)', 'important');
                // const id = TgObj.tgWebAppStartParam()
                const search = window.location.search.slice(1);
                const searchParams = new URLSearchParams(search);
                const id = searchParams.get('tgWebAppStartParam');
                // if (id) {
                //     navigate('/fundraiser-detail/' + id)
                // }
                if (id) {
                    navigate('/fundraiser-detail/' + id);
                }
            }
        }
    }, []);
    useEffect(() => {
        if (RawAddress) {
            // Smart.getBalanceProfile(RawAddress, isTestnet).then((bl) => {
            //     setBalance(fixAmount(bl.toString()))
            //     loadUser(RawAddress)
            // })
            loadUser(RawAddress);
        }
    }, [RawAddress]);
    return (_jsx(AppInner, { isTg: isTg, children: _jsx(TemporarilyDisabled, {}) }));
};
