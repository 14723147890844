// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KtUW_9RpKEV_DVhlKWL8{height:100vh;display:flex;align-items:center;justify-content:center;flex-direction:column}.KtUW_9RpKEV_DVhlKWL8 h1{margin-top:10px;text-align:center;font-size:32px;font-weight:700;color:var(--tg-theme-text-color) !important}`, "",{"version":3,"sources":["webpack://./src/pages/temporarily-disabled/temporarily-disabled.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,qBAAA,CACA,yBACE,eAAA,CACA,iBAAA,CACA,cAAA,CACA,eAAA,CACA,2CAAA","sourcesContent":[".content {\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  flex-direction: column;\n  h1 {\n    margin-top: 10px;\n    text-align: center;\n    font-size: 32px;\n    font-weight: 700;\n    color: var(--tg-theme-text-color) !important;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `KtUW_9RpKEV_DVhlKWL8`
};
export default ___CSS_LOADER_EXPORT___;
